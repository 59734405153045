<template>
	<BaseLoading v-if="data.edit.isLoading" is-full-page />
	<div v-else class="main-wrapper">
		<PaymentSettingInstallment
			:group-id="Number($route.params.id)"
			:method-id="Number($route.params.methodId)"
			:plans="channel.installmentPlans"
			:channel-id="Number($route.params.channelId)"
			class="mt-5"
		/>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import PaymentSettingInstallment from '@/components/PaymentSettingInstallment.vue';

export default {
	name: 'InstallmentChannel',

	components: {
		PaymentSettingInstallment,
	},
	computed: {
		...mapState('payments', {
			data: 'bankChannel',
		}),

		...mapGetters({
			channel: 'payments/bankChannelDetails',
		}),
	},
	async created() {
		await this.getBankChannel({
			methodId: this.$route.params.methodId,
			channelId: this.$route.params.channelId,
		});

		this.setBreadcrumbs([
			{
				title: 'Payment settings',
				route: { name: 'PaymentList' },
			},
			{
				title: `Installment credit card / Other banks`,
				route: {
					name: 'PaymentMethodSetting',
					params: { id: this.$route.params.id, methodId: this.$route.params.methodId },
				},
			},
		]);
	},
	methods: {
		...mapActions({
			getBankChannel: 'payments/getBankChannel',
			setBreadcrumbs: 'breadcrumb/setBreadcrumbs',
		}),
	},
};
</script>